import { CanActivateFn, Router } from '@angular/router';
import axios, { AxiosError } from 'axios';
import { environment } from '../../../environments/environment';
import { inject } from '@angular/core';
import { ToastService } from '../toast-service/toast.service';
import { TestTakingService } from '../test-taking-service/test-taking.service';
import {
  LinkStatus,
  TestTakingInformation,
} from '../../Interfaces/Types/testTakerInterface';

export const testTakingPhaseGuard: CanActivateFn = async () => {
  const toastService = inject(ToastService);
  const testTakingService = inject(TestTakingService);
  const router = inject(Router);

  if (testTakingService.inProgressReturnedData) {
    return true;
  }

  const testTakerId = testTakingService.getTestTakerIdFromParam();

  if (testTakerId) {
    const asssessmentTakerStateInfo = (await linkStatus(
      testTakerId
    )) as LinkStatus;
    if (asssessmentTakerStateInfo) {
      const { currentPhase } = asssessmentTakerStateInfo.track;
      if (currentPhase == 'TEST_TAKING') {
        testTakingService.inProgressReturnedData =
          asssessmentTakerStateInfo.assessmentTaker as TestTakingInformation;
        const assessmentDuration = Math.floor(
          (new Date(
            testTakingService.inProgressReturnedData?.estimatedEndTime
          ).getTime() -
            new Date().getTime()) /
            1000
        );
        localStorage.setItem(
          'assessmentTimeRemaining',
          assessmentDuration.toString()
        );
        testTakingService.trackData =
          asssessmentTakerStateInfo.track.testSubmissions;
        return true;
      }
      // check where to redrect user when testtakerId is not provided
      return false;
    }
    // check where to redrect user when testtakerId is not provided
    return false;
  } else {
    // check where to redrect user when testtakerId is not provided
    return false;
  }

  async function linkStatus(testTakerId: string) {
    try {
      const response = await axios.get(
        `${environment.apiBaseUrl}/test-taking/assessment/${testTakerId}/link-status`
      );
      return response.data.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const message = error.response?.data.message;
        switch (message) {
          case 'invalid':
            router.navigate([`invalid-link`]);
            break;
          case 'undue':
            router.navigate([`assessment-not-due`]);
            break;
          case 'expired':
            router.navigate([`link-expired`]);
            break;
          default:
            toastService.onShow(
              'error',
              error.response?.data.message,
              true,
              'error'
            );
            break;
        }
      }
      return false;
    }
  }
};
